import {request} from "./config"

// let NewUrl = 'http://192.168.0.128:81'
let NewUrl = ''

// 书库1
export const book_cate = (params) => request({
    method: 'get',
    url: NewUrl + 'web/book_cate',
    data: params
})

// 书库2
export const book_roon = (params) => request({
    method: 'get',
    url: NewUrl + 'web/book_room',
    data: params
})

