<template>
  <div class="book_list" flex between>
    <div class="rank_one">
      <img
        v-if="
          optins.cover_image &&
          optins.cover_image.match(/.*(\.png|\.jpg|\.jpeg|\.gif)$/)
        "
        :src="optins.cover_image"
        alt=""
        @click="look_bookinfo(optins.id)"
        :onerror="errorImg01"
      />
      <VIPShow
        :is_vip="optins.is_vip"
        :is_free="optins.charge_type"
        :css_style="{ fontSize: '12px' }"
      ></VIPShow>
    </div>
    <div class="item_right" column flex between>
      <div p-font class="name" @click="look_bookinfo(optins.id)">
        {{ optins.name || "- -" }}
      </div>
      <div class="item_author" flex black>
        <span
          class="item_author_span"
          @click="optins.author_id ? author_info(optins.author_id) : ''"
          :class="optins.author_id ? 'intro_author' : 'un_author'"
          ><i class="iconfont icon-touxiang"></i>
          {{ optins.author || "- -" }}</span
        >
        <!-- <span class="item_author_span" @click="author_info"><i class="iconfont icon-touxiang"></i> {{
          optins.author || "- -"
        }}</span> -->
        <!-- <span class="mid_text" v-if="optins.labels && optins.labels.name" >{{ optins.labels.name || "- -" }}</span> -->
        <span class="mid_text" v-if="optins && optins.labels">{{
          optins.labels[0].name || "- -"
        }}</span>
        <span>{{ optins.serial_status || "- -" }}</span>
      </div>
      <div class="intro">
        <p line-clamp words-hidden black>
          {{ optins.intro || "无" }}
        </p>
      </div>
      <div class="word">
        字数：<span>{{ optins.total_words + "万" || "- -" }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bookList",
  props: {
    optins: {
      type: Object,
    },
  },
  data() {
    return {
      classify: "",
      errorImg01:
        'this.src="' +
        require("../../../../public/static/default_book.png") +
        '"',
    };
  },
  mounted() {
    if (this.optins && typeof this.optins.labels === "object") {
      this.classify = this.optins.labels[0].name;
    }
  },
  methods: {
    // 作者详情
    author_info(id) {
      if (!this.optins.author) {
        this.$alert("作者不存在或数据错误", "提示信息", {
          confirmButtonText: "确定",
        });
      } else {
        const { href } = this.$router.resolve({
          path: "/show_author",
          query: {
            id: id,
          },
        });
        window.open(href, "_blank");
      }
    },
    // 书籍详情
    look_bookinfo(id) {
      const { href } = this.$router.resolve({
        path: "/show_book",
        query: {
          id: id,
        },
      });
      window.open(href, "_blank");
    },
  },
  created() {},
};
</script>
<style lang="scss" scoped>
* {
  font-size: 14px;
}
.book_list {
  width: 50%;
  padding: 10px 40px 10px 10px;
  .rank_one {
    width: 120px;
    height: 180px;
    overflow: hidden;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05), 0 0 5px #f9f2e9 inset;
    position: relative;
    img {
      cursor: pointer;
      width: 120px;
      height: 180px;
      transition: all 0.5s;
    }
    img:hover {
      transform: scale3d(1.1, 1.1, 1.1);
    }
  }
  .item_right {
    width: 65%;
    .name {
      font-size: 16px;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 250px;
    }
    .name:hover {
      color: #409eff;
    }
    .item_author {
      padding: 0 20px 0 0;
      width: 100%;
      color: #333333;
      span {
        width: calc(100% / 3);
        text-align: center;
        padding: 0 5px;
        line-height: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-left: 1px solid rgb(209, 208, 208);
      }
      span:nth-child(1) {
        border-left: 0px solid rgb(209, 208, 208);
      }
      .item_author_span {
        text-align: left;
        i {
          color: #dbdbdb;
        }
      }
      // .item_author_span:hover {
      //   color: #409eff;
      // }
      .intro_author {
        font-size: 14px;
        font-weight: normal;
        cursor: pointer;
        color: #333;
      }
      .intro_author:hover {
        color: rgba(79, 156, 251, 1);
      }
      .un_author {
        font-size: 14px;
        font-weight: normal;
        color: #333;
      }
    }
    .intro {
      -webkit-line-clamp: 4;
      height: 42%;
      width: 100%;
      letter-spacing: 2px;
      p {
        color: #666666 !important;
      }
    }
    .word {
      line-height: 30px;
      color: #999;
      font-size: 12px;
      & span {
        color: #999;
        font-size: 12px;
      }
    }
  }
}
.mid_text {
  // max-width: 80px;
  // overflow: hidden;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
}
</style>


