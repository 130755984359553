<template>
  <div class="type_menu" flex column>
    <div class="title">{{ options.name }}</div>
    <div class="item_box" flex row wrap>
      <span
        v-for="(item, index) in options.children"
        @click="choose_it(item, index)"
        :key="index"
        class="item"
        :class="menu_id === index ? 'choose_it' : 'un_choose'"
      >
        {{ item.name }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "typeMenu",
  props: {
    options: {
      type: Object,
    },
  },
  data() {
    return {
      menu_id: 0,
      // label:''
    };
  },
  mounted() {
    if (this.$route.query.label) {
      if (this.options.children && this.options.children.length > 0) {
        this.options.children.forEach((item, index) => {
          if (item.name == this.$route.query.label) {
            this.menu_id = index;
            this.$emit("choose_it", item);
          }
        });
      }
    }
  },
  methods: {
    choose_it(row, index) {
      // 获取到当前选中类型到父组件请求数据
      this.menu_id = index;
      this.$emit("choose_it", row);
    },
    // avoid_menu() {
    //   if (this.options.name === "作品分类") {
    //     this.options.children
    //   }
    // },
  },
  // watch: {
  //   "this.$route.query.label"() {
  //     this.avoid_menu();
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.type_menu {
  justify-content: flex-start;
  padding-bottom: 8px;
  border-bottom: 1px solid #eeeeee;
}
.title {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  padding: 0 5px;
}
.item_box {
  max-height: 350px;
  overflow: auto;
  .item {
    padding: 5px;
    margin: 2px;
    font-size: 14px;
    cursor: pointer;
  }
  .un_choose:hover {
    color: #409eff;
  }
  .choose_it {
    border-radius: 4px;
    background: #409eff;
    color: #fff;
  }
}
</style>
