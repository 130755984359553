<template>
  <div class="stack_room container" flex>
    <div class="room_left">
      <typeMenu
        :options="item"
        v-for="(item, index) in options"
        :key="index"
        @choose_it="choose_it($event, index)"
      ></typeMenu>
      <div class="type_menu" flex column>
        <div class="title">{{ fixed_menu[0].title }}</div>
        <div class="item_box" flex row wrap>
          <span
            class="item"
            @click="serial_it(item, index)"
            v-for="(item, index) in fixed_menu[0].list"
            :key="index"
            :class="menu_id_1 === index ? 'choose_it' : 'un_choose'"
          >
            {{ item.name }}
          </span>
        </div>
      </div>
      <div class="type_menu" flex column>
        <div class="title">{{ fixed_menu[1].title }}</div>
        <div class="item_box" flex row wrap>
          <span
            class="item"
            @click="charge_it(item, index)"
            v-for="(item, index) in fixed_menu[1].list"
            :key="index"
            :class="menu_id_2 === index ? 'choose_it' : 'un_choose'"
          >
            {{ item.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="room_right" flex column between v-loading="loading">
      <NoneData v-if="books_list.length === 0"></NoneData>
      <!-- <div class="hidden_box" v-if="books_list.length === 0" flex center align>
        <p>暂时没有数据哦~</p>
      </div> -->
      <div class="books_box" flex row wrap v-if="books_list.length > 0">
        <bookList
          :optins="item"
          v-for="(item, index) in books_list"
          :key="index"
        ></bookList>
      </div>
      <c-page
        :page="page"
        :callBack="loadData"
        v-if="books_list.length > 0"
      ></c-page>
    </div>
  </div>
</template>
<script>
import typeMenu from "./components/type-menu";
import bookList from "./components/book-list";
import { book_cate, book_roon } from "@/https/stack_room.js";
export default {
  name: "stack_room",
  components: {
    typeMenu,
    bookList,
  },
  data() {
    return {
      loading: false,
      page: { page: 1, limit: 10, total: 0 },
      params: [],
      options: [],
      fixed_menu: [
        {
          title: "状态",
          id: 3,
          list: [
            { name: "全部", id: "", parent_id: 3 },
            { name: "连载", id: 0, parent_id: 3 },
            { name: "完结", id: 1, parent_id: 3 },
          ],
        },
        {
          title: "资费",
          id: 4,
          list: [
            { name: "全部", id: "", parent_id: 4 },
            { name: "付费", id: 1, parent_id: 4 },
            { name: "免费", id: 0, parent_id: 4 },
          ],
        },
      ],
      books_list: [],
      previous_id: null,
      menu_id_1: 0,
      menu_id_2: 0,
      serial_status: "",
      charge_type: "",
      current: {},
    };
  },
  methods: {
    // 分页
    loadData() {
      this.get_list();
    },
    serial_it(item, index) {
      this.menu_id_1 = index;
      this.serial_status = item.id;
      this.get_list();
    },
    charge_it(item, index) {
      this.menu_id_2 = index;
      this.charge_type = item.id;
      this.get_list();
    },
    // 选中菜单
    choose_it(row) {
      this.params.forEach((item) => {
        if (row.parent_id === item.parent_id) {
          item.id = row.id;
        }
      });
      this.get_list();
    },
    // 获取书籍信息
    get_list() {
      this.loading = true;
      let channel = "";
      this.params.forEach((item) => {
        channel = channel + `${item.id},`;
      });
      channel = channel.substring(0, channel.lastIndexOf(","));
      //匹配channel为多个逗号时
      let regx = /^[,]+$/g;
      if (channel.match(regx)) {
        //将channel设置为''，返回所有书籍信息
        channel = "";
      }
      let param = {
        channel: channel,
        serial_status: this.serial_status,
        charge_type: this.charge_type,
        page: this.page.page,
        limit: this.page.limit,
      };
      book_roon(param)
        .then((res) => {
          this.books_list = res.data;
          this.page.total = res.total;
        })
        .finally(() => (this.loading = false));
    },
    handle_arr() {
      this.params = [];
      if (this.options && this.options.length) {
        this.options.forEach((item) => {
          let arr_y = [];
          arr_y[0] = item.id;
          arr_y[1] = item.list[0].id;
          this.params.push(arr_y);
        });
      }
    },
    // 获取菜单
    get_menu() {
      book_cate().then((res) => {
        this.options = res.data;
        res.data.forEach((item) => {
          let obj = {
            parent_id: item.id,
            id: "",
          };
          this.params.push(obj);
        });
        this.options.forEach((item) => {
          item.children.unshift({
            id: "",
            parent_id: item.id,
            name: "全部",
            children: [],
          });
        });

        this.get_list();
      });
    },
  },
  mounted() {
    this.get_menu();
  },
};
</script>
<style lang="scss" scoped>
.stack_room {
  margin-top: 20px;
  .room_left {
    background-color: #fff;
    width: 230px;
    margin-right: 20px;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    & div:last-of-type {
      border-bottom: none;
    }
    .type_menu {
      // justify-content: flex-start;
      padding-bottom: 8px;
      border-bottom: 1px solid #eeeeee;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 30px;
      padding: 0 5px;
    }
    .item_box {
      .item {
        padding: 5px;
        margin: 2px;
        cursor: pointer;
        font-size: 14px;
      }
      .un_choose:hover {
        color: #409eff;
      }
    }
    .choose_it {
      border-radius: 4px;
      background: #409eff;
      color: #fff;
    }
  }
  .room_right {
    width: calc(100% - 245px);
    // border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(245, 242, 242, 0.2);
    .books_box {
      width: 100%;
    }
    .hidden_box {
      color: #999;
      font-size: 14px;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
